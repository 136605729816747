import { NextPage } from 'next'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { useStytchB2BClient, useStytchMember } from '@stytch/nextjs/b2b'

import { ThemeSize } from '@lib/theme'

import { PrimaryButton } from '@components/core/buttons'
import Card from '@components/core/card'
import Container from '@components/core/container'
import { H1 } from '@components/core/headings'
import Module from '@components/core/module'
import Page from '@components/page'

const LoginPage: NextPage = () => {
  const router = useRouter()
  const stytch = useStytchB2BClient()
  const { isInitialized, member } = useStytchMember()

  const handleGoogleLogin = async () => {
    await stytch.oauth.google.discovery.start({
      discovery_redirect_url: `${location.origin}/api/v1/auth/verify`,
    })
  }

  const handleMicrosoftLogin = async () => {
    await stytch.oauth.microsoft.discovery.start({
      discovery_redirect_url: `${location.origin}/api/v1/auth/verify`,
    })
  }

  useEffect(() => {
    if (!isInitialized && member) {
      router.push('/dashboard')
    }
  }, [isInitialized, member])

  return (
    <Page noAuth noFooter noHeader>
      <Head>
        <title>Glisseo - Log in</title>
      </Head>

      <Module size={ThemeSize.Large}>
        <Container>
          <img
            src="/images/glisseo-logo-stacked-gradient.svg"
            alt="Glisseo"
            style={{ height: '120px', width: '91px' }}
          />
        </Container>
        <Container topMargin style={{ alignItems: 'center', height: '100%' }}>
          <Card style={{ maxWidth: '320px' }}>
            <H1
              style={{
                fontSize: '1rem',
                marginBottom: '2rem',
                textTransform: 'uppercase',
              }}
            >
              Log in
            </H1>
            <PrimaryButton
              disabled={!stytch}
              onClick={handleGoogleLogin}
              style={{ width: '100%' }}
            >
              Log in with Google
            </PrimaryButton>

            <PrimaryButton
              disabled={!stytch}
              onClick={handleMicrosoftLogin}
              style={{ marginTop: '2rem', width: '100%' }}
            >
              Log in with Microsoft
            </PrimaryButton>
          </Card>
        </Container>
      </Module>
    </Page>
  )
}

export default LoginPage
